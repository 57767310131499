import appConfig from 'appConfig';

import isMobile from './isMobile';

export const getVideoLink = () => {
    const language = navigator.languages?.[1]?.toUpperCase() || 'EN';

    if (isMobile.android()) {
        // @ts-ignore
        return appConfig.video.videoLinks.android[language] || appConfig.video.videoLinks.android['EN'];
    }

    // @ts-ignore
    return appConfig.video.videoLinks.ios[language] || appConfig.video.videoLinks.ios['EN'];
};
