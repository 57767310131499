import { Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import sentry from 'services/Sentry/SentryInstance';

import { useUserLoggedIn } from 'hooks/user';
import { useSelector } from 'hooks';

import { selectAbTestName } from './helpers';

export function useAnalytic() {
    const location = useLocation();
    const userData = useSelector(selectCurrentUser);
    const isAuthenticated = useUserLoggedIn();

    const { country, user_id, gender } = userData;

    const clientHandlerFn = (e: Error) => sentry.logError(e, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR);

    const getUtmSource = () => localStorage.getItem('utmSource');

    const getCountry = async () => {
        return new Promise((resolve) => {
            resolve(country);
        });
    };

    // TODO: refactor after changes in Provider
    const setAnalyticData = () => {
        Provider.provideData({
            country: async () => await getCountry(),
            abTestName: async () => (isAuthenticated ? await selectAbTestName() : null),
            utmSource: getUtmSource(),
            clientHandlerFn,
        });
        user_id && Provider.setUserId(user_id);
    };

    useEffect(() => {
        if (userData) {
            setAnalyticData();
        }
    }, [location?.pathname, isAuthenticated]);

    useEffect(() => {
        gender && localStorage.setItem('gender', gender);
    }, [gender]);
}
