export const SUBSCRIPTION_TITLE = {
    MAIN: 'subscription.heading.personalizedProgram',
    APP_ADDITIONAL_SUBSCRIPTION: 'subscriptions.additionalSubscription',
    OTHER: 'subscription.type.subscription',
};

export enum SUBSCRIPTION_STATES {
    ACTIVE = 'active',
    REDEMPTION = 'redemption',
    CANCELLED = 'cancelled',
    CHARGEBACK = 'chargeback',
    UPCOMING = 'upcoming', // client side type
    EXPIRED = 'expired', // client side type
    DISCOUNT = 'discount', // client side type
}

export enum DISCOUNT_TYPES {
    base = 'base',
    extra = 'extra',
}
