import { Provider } from 'wikr-core-analytics';
import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getCurrentUserSuccess, getUserFeatures } from 'store/user/actions';
import { notifyError } from 'store/notifications/actions';

import { authentication, setAuthenticationStatus, setAuthRedirectUrl } from '../actions';

import { userAPIResponseTransformer } from 'helpers/api';

import { UserResponse } from 'types/store/userResponse';

export function* authenticateSaga({ payload }: ReturnType<typeof authentication>) {
    const { onSuccess, onError } = payload;

    try {
        yield put(getUserFeatures());

        const userData: UserResponse = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        yield put(setAuthenticationStatus(true));

        Provider.setUserId(userData.user_id);

        if (onSuccess) {
            yield call(onSuccess);
        }

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        if (onError) {
            yield call(onError, error);
        }
    }
}
