export const DAYS_PER_WEEK = 7;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH_INCORRECT = 180; // TODO: Remove after product period is fixed in solid
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_NINE_MONTH = 273;
export const DAYS_PER_TEN_MONTH = 304;
export const DAYS_PER_ELEVEN_MONTH = 334;
export const DAYS_PER_YEAR = 365;
