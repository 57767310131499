import { PRIVATE } from 'router/routes';

import { FAQ_ANDROID_CODE, FAQ_IOS_CODE } from 'constants/links';

import isMobile from 'helpers/isMobile';

export const getFaqLinks = (language: string) => {
    const mobilePlatform = (isMobile.iOS() && FAQ_IOS_CODE) || (isMobile.android() && FAQ_ANDROID_CODE);

    if (mobilePlatform) {
        return `https://support.yoga-go.io/${language}/categories/${mobilePlatform}`;
    }

    return `https://support.yoga-go.io/${language}`;
};

export const getPersonalPlanLinks = (language: string) => {
    if (isMobile.any()) {
        return `https://support.yoga-go.io/${language}/articles/790018`;
    }

    return PRIVATE.PERSONAL_PLAN.path;
};
