import { ActionEventAction, ElementEventAction, EventTriggerFixed } from 'wikr-core-analytics';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticDeeplinkLoadClick = () => {
    trackEventFixed(EventTriggerFixed.deeplinkLoad);
};

export const sendAnalyticDownloadAppClick = (eventLabel: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.downloadApp,
        eventLabel,
    });
};
