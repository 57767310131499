import { createSelector } from 'reselect';

import { isMainSubscription } from 'helpers/subscriptions';

import { RootReducer } from 'types/store';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

export const selectAllSubscriptions = createSelector(
    selectSubscriptions,
    (subscriptions) => subscriptions.subscriptions
);

export const selectCancellationCandidates = createSelector(
    selectSubscriptions,
    (subscriptions) => subscriptions.cancellationCandidates
);

export const selectIsRequestSent = createSelector(selectSubscriptions, (subscriptions) => subscriptions.isRequestSent);

export const selectMainSubscription = createSelector(selectAllSubscriptions, (subscriptions) =>
    subscriptions.find((subscription) => isMainSubscription(subscription.product_code))
);

export const selectCancelReason = (state: RootReducer) => state.subscriptions.cancelReason;
