import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

export const StepNumber = styled(Box)`
    width: fit-content;
    display: inline-block;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
`;
