import { Currencies } from './currency';

export enum SubscriptionProductCodes {
    AppFullAccess = 'app_full_access',
    Main = 'main',
    face = 'face',
    Fatburn = 'fatburn',
    FatburnFace = 'fatburn_face',
    WorkoutBuilder = 'workout_builder',
    Highintense = 'highintense',
    Shoulders = 'shoulders',
    Pilates = 'pilates',
    Back = 'back',
    Weightloss = 'weightloss',
    Sevenintense = 'sevenintense',
    Hips = 'hips',
    Chair = 'chair',
    Wall = 'wall',
    VipSupport = 'vip_support',
    Sevenmin = 'sevenmin',
    Belly = 'belly',
    BellySofa = 'belly_sofa',
    Sofa = 'sofa',
    BellySevenmin = 'belly_sevenmin',
}

export interface Product {
    amount: number;
    currency: Currencies;
    id: string;
    name: string;
    payment_action: string;
    subscription_period: number;
    subscription_trial_period: number;
    started_from: string;
    trial: boolean;
    trial_amount: number;
    trial_currency?: Currencies;
    trial_period: number;
}

export interface IUpcomingProduct {
    started_from: string;
    amount: number;
    currency: Currencies;
    subscription_period: number;
    id: string;
    name: string;
    product_id: string;
    subscription_trial_period: number | null;
    trial: false;
}
