import { To } from 'react-router-dom';

import { RouterNavigateOptions } from '@remix-run/router';

export const routerHelper = (to: string, currentSearch: string) => {
    const currentSearchParams = new URLSearchParams(currentSearch);

    const mergeQueryParams = (): string => {
        currentSearchParams.forEach((value, key) => {
            currentSearchParams.set(key, value);
        });

        return `${to}?${currentSearchParams.toString()}`;
    };

    return {
        mergeQueryParams,
        isPersistNeeded: Boolean(currentSearchParams.get('__dev')),
    };
};

export const overriddenNavigate =
    (originalNavigate: {
        (to: number): Promise<void>;
        (to: To | null, options?: RouterNavigateOptions): Promise<void>;
    }) =>
    async (to: number | To | null, options?: RouterNavigateOptions): Promise<void> => {
        if (to === null) {
            // Handle the case when 'to' is null; just call the original navigate
            return originalNavigate(to, options);
        } else if (typeof to === 'number') {
            // Handle navigating by history steps (e.g., back or forward)
            return originalNavigate(to);
        } else {
            // Normal URL-based navigation with query parameter merging
            const { mergeQueryParams, isPersistNeeded } = routerHelper(to as string, window.location.search);

            const destination = isPersistNeeded ? mergeQueryParams() : to;

            return originalNavigate(destination, options);
        }
    };
