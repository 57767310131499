import { Image, Text } from 'wikr-core-components';

import Modal from 'components/Modal';

import * as S from './styled';

import sandClock from 'assets/emoji/hourglass_flowing_sand.webp';
import loader from './img/loader.svg';

import { WithLoaderModalPropsType } from './types';

const WithLoaderModal = ({ img = sandClock, title }: WithLoaderModalPropsType) => (
    <Modal isOpen style={{ content: { padding: '16px 16px 32px' } }} withRoundedCorners={false}>
        <S.LoaderContainer data-locator="processingBlock">
            {img && <Image maxWidth={72} center alt="emoji" src={img} isForceVisible />}
            <Text type="h5" text={title} mb={24} center dataLocator="processingTitle" />
            <Image dataLocator="processingSpiner" maxWidth={32} center alt="loader" src={loader} isForceVisible />
        </S.LoaderContainer>
    </Modal>
);

export default WithLoaderModal;
