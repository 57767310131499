import { FC } from 'react';

import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';

import { useSelector } from 'hooks';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

import { InstallAppButtonProps } from './types';

const InstallAppButton: FC<InstallAppButtonProps> = ({ type, isMobile = false, customLink }) => {
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const deepLinkUrl = useSelector(selectDeepLink);

    return (
        <>
            {isLoading ? (
                <Skeleton width={150} height={45} borderRadius={8} marginLeft={8} />
            ) : (
                <S.StoreBtn isMobile={isMobile} link={customLink || deepLinkUrl} type={type} />
            )}
        </>
    );
};

export default InstallAppButton;
