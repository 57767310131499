import { UserStore } from 'store/user/types';

import { YogaType } from 'constants/personalPlan';

import { getBMI } from 'helpers/user/personalization';
import { getFormattedDate } from 'helpers/date';

import type { IWorkoutQueryParams } from 'types/personalPlan';
import { IPersonalListDayResponseExtended } from 'types/personalPlan';

type IGetWorkoutQueryParams = ({
    currentDay,
    user,
    yoga_type,
}: {
    currentDay: IPersonalListDayResponseExtended;
    user: UserStore;
    yoga_type: YogaType;
}) => IWorkoutQueryParams;

export const getWorkoutQueryParams: IGetWorkoutQueryParams = ({ currentDay, user, yoga_type }) => {
    const { weight, height, date_of_birth } = user;
    const { workout_duration, focus_zones, focus, level, savasana_duration, warm_up_enabled } = currentDay;
    const birthdate = date_of_birth ? getFormattedDate(date_of_birth, 'YYYY-MM-DDTHH:mm:ss') : ''; // HH:mm:ss is added to make the birthdate format the same as on mobile
    const bmi = getBMI(weight, height);

    return {
        workout_duration,
        body_parts: JSON.stringify(focus_zones),
        focus,
        level,
        savasana_duration,
        warm_up_enabled,
        yoga_type: yoga_type || YogaType.Classic,
        birthdate,
        bmi,
    };
};
