import { put, call } from 'redux-saga/effects';

import { removeToken } from 'api/utils/tokenManagement';
import BrowserStorage from 'api/utils/browserStorage';

import { resetUserData } from 'store/user/actions';
import { resetSubscriptions } from 'store/subscriptions/actions';

import { setAuthenticationStatus } from '../actions';

export function* logoutSaga() {
    yield call(removeToken);
    yield call(BrowserStorage.removeItem, 'isAppDownloaded');

    yield put(setAuthenticationStatus(false));
    yield put(resetUserData());
    yield put(resetSubscriptions());
}
