import { Text } from 'wikr-core-components';

import { useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import { FeedbackModalTemplatePropsType } from './types';

const FeedbackModalTemplate = ({
    withCloseButton,
    withRoundedCorners = false,
    children,
    image,
    subtitle,
    title,
    contactUs,
    dataLocators = {},
    onClose,
}: FeedbackModalTemplatePropsType) => {
    const { closeModal } = useModals();

    const handleClose = () => {
        closeModal();
        onClose?.();
    };

    return (
        <Modal
            onClose={handleClose}
            isOpen
            style={{ content: { padding: '16px' } }}
            withCloseButton={withCloseButton}
            withRoundedCorners={withRoundedCorners}
            dataLocator={dataLocators.body}
        >
            {image?.src && (
                <S.Emoji mt={image.mt} maxWidth={48} center alt="emoji" src={image.src} mb={24} isForceVisible />
            )}
            <Text dataLocator={dataLocators.title} type="h5" text={title} mb={12} center />
            {subtitle && <Text dataLocator={dataLocators.subTitle} text={subtitle} mb={24} center />}
            {children}
            {contactUs && <ContactUsLink {...contactUs} mb={12} />}
        </Modal>
    );
};

export default FeedbackModalTemplate;
