import { ActiveGroup, Experiment } from './utils/experiments';
import { STAGE_EXPERIMENT_KEY } from './utils/const';

import { isProd } from 'helpers/utils';

import { getAll } from 'firebase/remote-config';

import { activationConfigPromise, remoteConfig } from './initFirebase/initFirebase';

export const getExperimentsByEnv = (): Promise<Record<Experiment, string>> =>
    activationConfigPromise.then(() => {
        const allExperiments = getAll(remoteConfig);

        return Object.entries(allExperiments).reduce<Record<string, string>>(
            (envBasedExperiments, [experiment, variant]) => {
                const isDevEnvExperiment = experiment.includes(STAGE_EXPERIMENT_KEY);

                if ((isProd() && !isDevEnvExperiment) || (!isProd() && isDevEnvExperiment)) {
                    envBasedExperiments[experiment] = variant.asString() || ActiveGroup.Control;
                }

                return envBasedExperiments;
            },
            {}
        );
    });

export const getAbAutoTestsParams = () => {
    const searchParams = new URLSearchParams(window.location.search);

    return {
        aqa: searchParams.get('__dev'),
        experiment: searchParams.get('experiment'),
        variantName: searchParams.get('variant_name'),
    };
};
