/* eslint-disable max-lines */
import {
    all,
    call,
    cancel,
    cancelled,
    delay,
    fork,
    join,
    put,
    race,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { CancelledEffect } from '@redux-saga/core/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { getUserFeatures } from 'store/user/actions';
import { closeModal, openModal } from 'store/modals/actions';

import {
    cancelSubscription,
    fetchDiscountSubscription,
    fetchSubscriptions,
    restoreSubscription,
    sendFeedback,
    setCancellationCandidates,
    setDiscountSubscription,
    setIsRequestSent,
    setSubscriptions,
    setSubscriptionsFetchingStatus,
    updateSubscription,
} from './actions';
import { notifyError, notifySuccess } from '../notifications/actions';

import {
    CANCEL_SUBSCRIPTION,
    DISCARD_SUBSCRIPTION_CANCELLATION,
    FETCH_DISCOUNT_SUBSCRIPTION,
    FETCH_USER_SUBSCRIPTIONS,
    RESTORE_SUBSCRIPTION,
    SEND_SUBSCRIPTION_FEEDBACK,
    UPDATE_SUBSCRIPTION,
} from './actionTypes';

import { SUBSCRIPTION_STATES } from 'constants/subscriptions';
import { DISCOUNT_APPLIED_ERROR, DISCOUNT_APPLIED_SUCCESS } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticDiscountAppliedResult, sendAnalyticFrontCancelSubscriptionRequest } from 'services/analytics';

import {
    getChangeSubscriptionSuccessModalData,
    getErrorModalData,
    getRestoreSuccessModalData,
} from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ICancelSubscription, IDiscountSubscription, ISubscription } from 'types/subscription';

import { selectCancelReason } from './selectors';

export function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(response));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        yield put(notifyError('getSubscription error'));
    }
}

function* makeSubscriptionCancelling({ payload: cancellationCandidates }: ReturnType<typeof cancelSubscription>) {
    const successCount = { current: 0 };
    const errorCount = { current: [] };

    try {
        const cancelReason: string | null = yield select(selectCancelReason);

        const tasks: Task[] = yield all(
            cancellationCandidates.map((candidate) =>
                fork(callUnsubscribe, candidate, successCount, errorCount, cancelReason)
            )
        );

        yield race({
            completed: all(tasks.map((task) => join(task))),
            cancelled: take(DISCARD_SUBSCRIPTION_CANCELLATION),
        });

        yield cancel(tasks);
    } catch (error) {
        cancellationCandidates[0].onError();
    } finally {
        yield put(setIsRequestSent(false));

        const successTotal = successCount.current;
        const errorTotal = errorCount.current.length;

        if (errorTotal > 0) {
            yield callErrorCancellingBehaviour({ errorCount, cancellationCandidates });
        } else if (successTotal > 0) {
            yield callSuccessCancellingBehaviour({ successTotal, cancellationCandidates });
        }
    }
}

function* callErrorCancellingBehaviour({
    errorCount,
    cancellationCandidates,
}: {
    errorCount: Record<string, string[]>;
    cancellationCandidates: ICancelSubscription[];
}) {
    const subscriptionsCancellationFailedPartially = errorCount.current.length < cancellationCandidates.length;

    const modalData =
        errorCount.current.length > 1
            ? getErrorModalData({
                  title: 'subscription.cancel.error.title.multi',
                  subtitle: 'subscription.cancel.error.subtitle.multi',
              })
            : getErrorModalData({
                  title: 'subscription.cancel.error.title.single',
                  subtitle: 'subscription.cancel.error.subtitle.single',
              });

    if (subscriptionsCancellationFailedPartially) {
        const candidate = cancellationCandidates.find(
            (e) => e.currentSubscription.external_id === errorCount.current[0]
        );

        yield put(setCancellationCandidates(candidate ? [candidate.currentSubscription] : null));
    }

    yield put(
        openModal(ModalName.CancelSubscriptionErrorModal, {
            ...modalData,
            wasSecondCanceled: subscriptionsCancellationFailedPartially,
        })
    );
}

function* callSuccessCancellingBehaviour({
    successTotal,
    cancellationCandidates,
}: {
    successTotal: number;
    cancellationCandidates: ICancelSubscription[];
}) {
    const firstCandidate = cancellationCandidates[0];
    const followingSubscription = firstCandidate.secondSubscription;

    const isSingleCandidate = cancellationCandidates.length === 1;
    const hasFollowingActiveSubscription =
        followingSubscription &&
        followingSubscription.state === SUBSCRIPTION_STATES.ACTIVE &&
        !Boolean(followingSubscription.cancelled_at);

    const areAllSubscriptionsCancelled = successTotal > 1 && successTotal === cancellationCandidates.length;

    if (isSingleCandidate && hasFollowingActiveSubscription) {
        yield put(
            openModal(ModalName.NoteModal, {
                cancelledProductCode: cancellationCandidates[0].currentSubscription.product_code,
            })
        );
    } else if (areAllSubscriptionsCancelled) {
        yield put(setCancellationCandidates(null));
        yield put(closeModal());
    } else {
        yield put(closeModal());
    }
}

function* sendSubscriptionFeedback({ payload }: ReturnType<typeof sendFeedback>) {
    try {
        yield call(api.subscriptions.sendFeedback, payload);
    } catch (error) {
        console.error(error);
    }
}

function* callUnsubscribe(
    candidate: ICancelSubscription,
    successCount: { current: number },
    errorCount: { current: string[] },
    cancelReason: string | null
) {
    try {
        yield delay(3000);

        yield put(setIsRequestSent(true));
        sendAnalyticFrontCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: candidate.currentSubscription.external_id,
            cancellation_reason: cancelReason,
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        yield put(fetchSubscriptions());

        successCount.current += 1;
        candidate.onSuccess();
    } catch (error) {
        errorCount.current.push(candidate.currentSubscription.external_id);
        candidate.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            yield put(notifySuccess('subscription.cancellation.response.abort'));
            candidate.onCancel();
        }
    }
}

function* changeCurrentSubscription({ payload }: ReturnType<typeof updateSubscription>) {
    try {
        yield api.subscriptions.updateSubscription(payload);

        yield put(fetchSubscriptions());
        yield put(openModal(ModalName.SuccessModal, getChangeSubscriptionSuccessModalData(payload.product)));

        yield call(sendAnalyticDiscountAppliedResult, DISCOUNT_APPLIED_SUCCESS, payload.product.name);
    } catch (error) {
        yield put(
            openModal(
                ModalName.ChangeSubscriptionErrorModal,
                getErrorModalData({
                    title: 'subscription.discount.error.title',
                    subtitle: 'subscription.discount.error.subtitle',
                })
            )
        );

        yield call(
            sendAnalyticDiscountAppliedResult,
            DISCOUNT_APPLIED_ERROR,
            'Subscription plan not updated due to a technical issue'
        );
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscription>) {
    try {
        const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscription, payload);

        yield delay(3000);
        yield put(setDiscountSubscription(response));
    } catch (error) {
        console.error(error);
    }
}

function* requestRestoreSubscription({ payload }: ReturnType<typeof restoreSubscription>) {
    const { external_id, product } = payload;

    try {
        yield call(api.subscriptions.restoreSubscription, { external_id });

        yield put(openModal(ModalName.SuccessModal, getRestoreSuccessModalData(product)));

        yield call(trackScreenLoad, 'restore_success', {
            subscription_id: external_id,
            period: product.subscription_period,
            tariff: product.name,
            content_id: product.id,
        });

        yield put(fetchSubscriptions());
        yield put(getUserFeatures());
    } catch (error) {
        yield put(openModal(ModalName.RestoreSubscriptionErrorModal, payload));

        console.error(error);
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(SEND_SUBSCRIPTION_FEEDBACK, sendSubscriptionFeedback),
        takeLatest(UPDATE_SUBSCRIPTION, changeCurrentSubscription),
        takeLatest(FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(RESTORE_SUBSCRIPTION, requestRestoreSubscription),
    ]);
}
