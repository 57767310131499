import { deleteInstallations, getInstallations } from 'firebase/installations';
import { initFirebase } from './initFirebase';
import { FIREBASE_DBs, FIREBASE_TTL_KEY, MAX_FETCH_INTERVAL_DEV_ENV } from './const';

export const clearLocalConfig = async () => {
    const isExpired = checkFirebaseTokenExpired();

    if (isExpired) {
        localStorage.removeItem(FIREBASE_TTL_KEY);

        const installations = getInstallations();

        await deleteInstallations(installations);

        await clearLocalDBs();

        initFirebase();
    }
};

export const clearLocalDBs = async () => {
    const databases = await indexedDB.databases();

    databases.forEach((db) => {
        if (db.name && FIREBASE_DBs.includes(db.name)) {
            indexedDB.deleteDatabase(db.name);
        }
    });
};

export const checkFirebaseTokenExpired = () => {
    const storedTimeString = localStorage.getItem(FIREBASE_TTL_KEY);

    if (storedTimeString) {
        const storedTime = new Date(storedTimeString).getTime();

        const currentTime = new Date().getTime();

        const timeDifference = currentTime - storedTime;

        return timeDifference > MAX_FETCH_INTERVAL_DEV_ENV;
    } else {
        localStorage.setItem(FIREBASE_TTL_KEY, Date());
    }

    return false;
};
