import { Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

import BrowserStorage from 'api/utils/browserStorage';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';
import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { getDeepLinkRequest } from 'store/deepLink/actions';

import { DESKTOP_HARDCODE_LINKS } from 'constants/links';
import { DOWNLOAD_APP_EVENT_LABELS } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import {
    sendAnalyticCancelOfferClick,
    sendAnalyticDeeplinkLoadClick,
    sendAnalyticDownloadAppClick,
    trackScreenLoadCancelOffer,
} from 'services/analytics';

import { useDispatch, useSelector } from 'hooks';

import { getValuesByKey } from 'helpers/utils';
import isMobile from 'helpers/isMobile';
import { getVideoLink } from 'helpers/getVideoLink';

import { Step } from 'components/Step';
import Skeleton from 'components/Skeleton';
import Logo from 'components/Logo';
import * as S from 'components/InstallAppSteps/styled';
import InstallAppButton from 'components/InstallAppButton';

import scanIconImg from 'assets/img/scan-icon-phone.svg';

import { CancelOfferAction } from 'types/subscription';

const InstallAppSteps = ({
    stepsNumber: { getAppStepNumber, logInStepNumber },
    downloadButtonText,
    screenId,
    isCancelOffer,
}: {
    stepsNumber: { getAppStepNumber: number; logInStepNumber: number };
    downloadButtonText?: string;
    screenId?: string;
    isCancelOffer?: boolean;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const deepLinkUrl = useSelector(selectDeepLink);
    const videoLink = getVideoLink();
    const isAndroid = isMobile.android();
    const isIos = isMobile.iOS();
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const subscription_id = getValuesByKey(cancellationCandidates, 'product', 'id');

    const [isAppDownloaded, setIsAppDownloaded] = useState(BrowserStorage.getItem('isAppDownloaded'));

    useEffect(() => {
        if (!deepLinkUrl) dispatch(getDeepLinkRequest());
    }, [deepLinkUrl, dispatch]);

    useEffect(() => {
        isCancelOffer
            ? trackScreenLoadCancelOffer({ screenIdParam: screenId, data: { subscription_id, offer: 'login' } })
            : trackScreenLoad();
    }, []);

    useEffect(() => {
        deepLinkUrl && sendAnalyticDeeplinkLoadClick();
    }, [deepLinkUrl]);

    const handleDownloadApp = (eventLabel: string) => {
        setIsAppDownloaded(true);
        BrowserStorage.setItem('isAppDownloaded', true);

        window.open(deepLinkUrl, '_blank', 'noopener,noreferrer');

        isCancelOffer
            ? sendAnalyticCancelOfferClick({
                  screenIdParam: screenId,
                  data: {
                      action: CancelOfferAction.ACCEPT,
                      subscription_id,
                  },
              })
            : sendAnalyticDownloadAppClick(eventLabel);
    };

    const handleLinkDownloadApp = (eventLabel: string) => {
        setIsAppDownloaded(true);
        BrowserStorage.setItem('isAppDownloaded', true);

        isCancelOffer
            ? sendAnalyticCancelOfferClick({
                  screenIdParam: screenId,
                  data: {
                      subscription_id,
                      action: CancelOfferAction.ACCEPT,
                  },
              })
            : sendAnalyticDownloadAppClick(eventLabel);
    };

    return (
        <>
            <S.StepContainer mb={24}>
                <div data-locator="step3Container">
                    <Step stepNumber={getAppStepNumber}>
                        <>
                            <Text
                                type="large-text"
                                bold
                                mb={16}
                                center
                                text={t(isAppDownloaded ? 'main.step.title.getApp' : 'main.step.title.getAppYoga')}
                            />
                            {!isIos && !isAndroid ? (
                                <S.DownloadWrapper>
                                    <Image center maxWidth={48} mb={20} alt="scan-icon-img" src={scanIconImg} />
                                    <Text mb={56} center text={t('main.step.title.scanCode')} />
                                    <S.ScanQrCodeWrapper
                                        paddingX={20}
                                        paddingTop={20}
                                        paddingBottom={20}
                                        borderRadius={16}
                                        backgroundColor="surface-default"
                                    >
                                        {isLoading ? (
                                            <Skeleton borderRadius={8} width={200} height={200} />
                                        ) : (
                                            <QRCode size={186} value={deepLinkUrl} />
                                        )}
                                    </S.ScanQrCodeWrapper>
                                </S.DownloadWrapper>
                            ) : (
                                <S.FlexWrapper>
                                    {isIos && (
                                        <div
                                            onClick={() => handleLinkDownloadApp(DOWNLOAD_APP_EVENT_LABELS.ios_top)}
                                            data-locator="appleStoreLink"
                                        >
                                            <InstallAppButton isMobile type="apple" />
                                        </div>
                                    )}
                                    {isAndroid && (
                                        <div
                                            onClick={() => handleLinkDownloadApp(DOWNLOAD_APP_EVENT_LABELS.android_top)}
                                            data-locator="googleStoreLink"
                                        >
                                            <InstallAppButton isMobile type="google" />
                                        </div>
                                    )}
                                </S.FlexWrapper>
                            )}
                        </>
                    </Step>
                </div>

                <div data-locator="step4Container">
                    <Step stepNumber={logInStepNumber}>
                        <>
                            <Text type="large-text" bold mb={16} center text={t('basics.login')} />
                            <S.Video maxWidth={190} mb={30} alt="video" src={videoLink} />
                        </>
                    </Step>
                </div>

                <S.MobileWrapper>
                    <Button
                        dataLocator="deeplinkBtn"
                        onClick={() => handleDownloadApp(DOWNLOAD_APP_EVENT_LABELS.general_bot)}
                        backgroundColor="primary-default"
                        mb={24}
                        text={t(downloadButtonText ? downloadButtonText : 'main.button')}
                    />
                </S.MobileWrapper>
            </S.StepContainer>

            <S.TabletWrapper>
                <S.Footer
                    borderRadius={16}
                    paddingBottom={24}
                    paddingTop={24}
                    paddingX={24}
                    backgroundColor="surface-secondary"
                    mb={56}
                >
                    <Logo />
                    <S.FlexWrapper>
                        <div onClick={() => handleLinkDownloadApp(DOWNLOAD_APP_EVENT_LABELS.ios_bot)}>
                            <InstallAppButton customLink={DESKTOP_HARDCODE_LINKS.APPLE} type="apple" />
                        </div>
                        <div onClick={() => handleLinkDownloadApp(DOWNLOAD_APP_EVENT_LABELS.android_bot)}>
                            <InstallAppButton type="google" customLink={DESKTOP_HARDCODE_LINKS.GOOGLE} />
                        </div>
                    </S.FlexWrapper>
                </S.Footer>
            </S.TabletWrapper>
        </>
    );
};

export default InstallAppSteps;
