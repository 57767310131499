import { Provider } from 'wikr-core-analytics';
import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getCurrentUserSuccess, getUserFeatures } from 'store/user/actions';
import { notifyError } from 'store/notifications/actions';

import { setAuthenticationStatus, signInError, signInRequest, signInSuccess } from '../actions';

import { selectAbTestName } from 'services/analytics/helpers';

import { userAPIResponseTransformer } from 'helpers/api';

import { UserResponse } from 'types/store/userResponse';

export function* signInSaga({ payload }: ReturnType<typeof signInRequest>) {
    const { userData, onSuccess, onError } = payload;

    try {
        const userDataResponse: UserResponse = yield call(api.user.signIn, userData);

        yield put(getUserFeatures());
        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userDataResponse)));
        yield put(setAuthenticationStatus(true));
        yield put(signInSuccess());

        const getCountry = async () => {
            return new Promise((resolve) => {
                resolve(userDataResponse.country);
            });
        };

        Provider.provideData({
            country: async () => await getCountry(),
            abTestName: async () => await selectAbTestName(),
        });
        Provider.setUserId(userDataResponse.user_id);

        onSuccess && onSuccess(userDataResponse);
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));

        if (error?.message) {
            yield put(signInError(error.message));

            if (onError) {
                onError(error.message);
            }
        }
    }
}
