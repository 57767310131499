import styled from 'styled-components';

export const DiscountModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 24px;

    @media (max-width: 768px) {
        padding-bottom: 100px;
    }
`;
