import { AbAutoTestsParamsReturnValue } from 'services/Firebase/utils/types';
import modificators from 'services/Firebase/utils/modificators';
import { Experiment } from 'services/Firebase/utils/experiments';
import { STAGE_EXPERIMENT_KEY } from 'services/Firebase/utils/const';
import { getAbAutoTestsParams, getExperimentsByEnv } from 'services/Firebase/helpers';

export const changePathNameToScreenId = (pathName = window.location.pathname) => {
    const screenId = pathName.replace(/^\/|\/$/g, '').replace(/(\/([a-z]{2})\/)|\//g, '_');

    return screenId || 'log_in';
};

export const getAbTestNameFromAutoTests = ({
    experiment,
    variantName,
}: Pick<AbAutoTestsParamsReturnValue, 'experiment' | 'variantName'>) => `${experiment}__${variantName}`;

export const getAbTestNameFromExperiment = async () => {
    const envBasedExperiments = await getExperimentsByEnv();

    const abTestName = Object.entries(envBasedExperiments)
        .map(([key, value]) => {
            const envBasedKey = key.includes(STAGE_EXPERIMENT_KEY) ? key.replace(`_${STAGE_EXPERIMENT_KEY}`, '') : key;

            const modificator = modificators[envBasedKey as Experiment];

            const experimentVariant = modificator?.(value) ?? value;

            return [key, experimentVariant].join('__');
        })
        .join(', ');

    return abTestName;
};

export const selectAbTestName = async () => {
    const { aqa, ...autoTestParams } = getAbAutoTestsParams();

    return aqa ? getAbTestNameFromAutoTests(autoTestParams) : await getAbTestNameFromExperiment();
};
