import { DISCOUNT_TYPES, SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { Product, SubscriptionProductCodes, IUpcomingProduct } from './product';
import { Currencies } from './currency';

export interface ISubscription {
    expired_date: string;
    external_id: string;
    id: number;
    price: string;
    product: Product;
    product_code: SubscriptionProductCodes;
    purchase_date: string;
    state: SUBSCRIPTION_STATES;
    is_trial: boolean;
    cancelled_at: string;
    upcoming_product: IUpcomingProduct | null;
    discount_type: string | null;
    discount_granted_at: string | null;
}

export interface ISubscriptionFeedback {
    reason: string;
    comment: string;
}

export interface ICancelSubscriptionRequest {
    external_id: string;
    cancellation_reason: string;
}

export interface ICancelSubscription {
    currentSubscription: ISubscription;
    secondSubscription?: ISubscription;
    cancelReason?: string;
    onCancel: () => void;
    onSuccess: () => void;
    onError: () => void;
}

export interface ICancelSubscriptionOld {
    externalId: string;
    onCancel: () => void;
    onSuccess: () => void;
    onError: () => void;
}

export interface IFetchDiscountSubscription {
    discount_type: DISCOUNT_TYPES;
    external_id: string;
}

export interface IDiscountSubscription {
    id: string;
    name: string;
    amount: number;
    currency: Currencies;
    trial: boolean;
    subscription_period: number;
    subscription_trial_period: null | number;
    started_from: string;
    product_id: string;
}

export interface IUpdateSubscriptionData {
    product: IDiscountSubscription;
    external_id: string;
    discount_type: DISCOUNT_TYPES;
}

export enum CancelOfferAction {
    ACCEPT = 'accept',
    CANCEL = 'cancel',
    CLOSE = 'close',
}
