import config from 'config';

import { isProd } from 'helpers/utils';

export const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
    measurementId: config.FIREBASE_MEASUREMENT_ID,
};

export const MAX_RETRIES = 2;

export const MAX_FETCH_INTERVAL_DEV_ENV = 60_000;

export const remoteConfigSettings = {
    minimumFetchIntervalMillis: isProd() ? 43_200_000 : MAX_FETCH_INTERVAL_DEV_ENV, // fetch(cache) interval in milliseconds: 1min for non-prod, 12 hours for prod
    fetchTimeoutMillis: 15_000, // timeout in milliseconds
};

export const FIREBASE_TTL_KEY = 'firebaseTTL';

export const FIREBASE_DBs = ['firebase_remote_config'];
