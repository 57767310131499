import styled from 'styled-components';

export const Container = styled.div`
    max-width: 900px;
    margin: 0 auto;
    padding: 32px 16px 24px;
`;

export const CancelSubscriptionButton = styled.div`
    max-width: 400px;
    margin: -32px auto 0;
`;
